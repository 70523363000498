/* fonts */

/* advent-pro-300 - latin */

@font-face {
  font-family: 'Advent Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Advent Pro Light'), local('AdventPro-Light'),
    url('../fonts/advent-pro-v11-latin-300.woff2') format('woff2'),
    url('../fonts/advent-pro-v11-latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* advent-pro-500 - latin */

@font-face {
  font-family: 'Advent Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Advent Pro Medium'), local('AdventPro-Medium'),
    url('../fonts/advent-pro-v11-latin-500.woff2') format('woff2'),
    url('../fonts/advent-pro-v11-latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* advent-pro-600 - latin */

@font-face {
  font-family: 'Advent Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Advent Pro SemiBold'), local('AdventPro-SemiBold'),
    url('../fonts/advent-pro-v11-latin-600.woff2') format('woff2'),
    url('../fonts/advent-pro-v11-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-200 - latin */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/poppins-v13-latin-200.eot');
  /* IE9 Compat Modes */
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
    url('../fonts/poppins-v13-latin-200.eot?#iefix') format('embedded-opentype'),
    url('../fonts/poppins-v13-latin-200.woff2') format('woff2'),
    url('../fonts/poppins-v13-latin-200.woff') format('woff'),
    url('../fonts/poppins-v13-latin-200.ttf') format('truetype'),
    url('../fonts/poppins-v13-latin-200.svg#Poppins') format('svg');
  /* Legacy iOS */
}

/* IBMPlexSerifItalic - latin */
@font-face {
  font-family: 'IBMPlexSerifItalic';
  font-style: normal;
  font-weight: normal;
  src: local('IBM Plex Serif Italic'), url('../fonts/IBMPlexSerif-Italic.woff') format('woff');
}


/* general */

html,
body {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

body,
input {
  font-family: 'Advent Pro';
  margin: 0;
  position: relative;
}

button {
  font-family: 'IBMPlexSerifItalic';
  line-height: inherit;
}

a:link,
a:visited {
  color: inherit;
}

/* specific */

.sticky-footer {
  justify-content: center;
  text-align: center;
  bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 6;
  opacity: 0.9;
  border-top: 0.0625rem solid #d2694a;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  -webkit-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.9;
  }
}

.sticky-footer__dismiss {
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-right: 0.625rem;
  margin-top: 0.625rem;
}

@media (min-width: 576px) {
  .sticky-footer__dismiss {
    align-self: center;
    margin-top: 0;
  }

  .sticky-footer br {
    display: none;
  }

  .sticky-footer__learn {
    margin-left: 8px;
  }
}

@media (min-width: 768px) {
  .sticky-footer__dismiss {
    margin-right: 2rem;
  }
}

.text {
  color: #301e1c;
  font-size: 0.875rem;
  /* 14px; */
  line-height: 1.71;
  letter-spacing: 0.109375rem;
  /* 1.75px; */
  font-weight: 300;
}

.text--solar {
  color: #d2694a;
  font-size: 0.75rem;
  /* 12px; */
  line-height: 2;
  letter-spacing: 0.0625rem;
  /* 1px; */
  font-weight: 500;
  text-align: center;
}

.text--mailing {
  color: #edd8e9;
  font-size: 0.875rem;
  /* 14px; */
  font-weight: 300;
  line-height: 1.71;
  letter-spacing: 0.109375rem;
  /* 1.75px; */
}

.text--bold {
  font-weight: 600;
}

@media (min-width: 768px) {
  .text {
    font-size: 1rem;
    /* 16px; */
  }
}

.margin--unset {
  -webkit-margin-before: unset;
  margin-block-start: unset;
  -webkit-margin-after: unset;
  margin-block-end: unset;
}

.header {
  height: 5.25rem;
  /* 84px; */
  flex-wrap: nowrap !important;
  justify-content: space-between;
  align-items: center;
}

.header__title {
  color: #edd8e9;
  font-family: 'IBMPlexSerifItalic';
  /*font-style: italic;*/
  font-size: 3.125rem;
  /* 50px; */
  font-weight: 200;
  letter-spacing: 0.574375rem;
  /* 9.19px; */
  margin-left: 1.875rem;
  /* 30px; */
}

@media (min-width: 768px) {
  .header {
    height: 15.4375rem;
    /* 247px; */
  }

  .header__title {
    font-size: 5rem;
    /* 120px; */
    line-height: normal;
    letter-spacing: 1.37875rem;
    /* 22.06px; */
  }
}

.menu {
  margin-right: 1.875rem;
  /* 30px; */
}

.menu__button {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 2.5rem;
  /* 40px; */
  z-index: 5;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu__button span {
  width: 3.0625rem;
  /* 49px; */
  height: 0.125rem;
  /* 2px; */
  background-color: #edd8e9;
  position: relative;
  display: block;
  transition: all 1s ease-out;
}

.menu__button span:before {
  top: -0.875rem;
  /* -14px; */
  visibility: visible;
}

.menu__button span:after {
  top: 0.875rem;
  /* 14px; */
}

.menu__button span:before,
.menu__button span:after {
  content: '';
  width: 3.0625rem;
  /* 49px; */
  height: 0.125rem;
  /* 2px; */
  background-color: #edd8e9;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.menu-is-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.overlay {
  font-family:'IBMPlexSerifItalic';
  /* 46px; */
  justify-content: center;
  height: 100%;
  width: 100vw;
  background-color: #edd8e9;
  z-index: 999;
  visibility: hidden;
  position: fixed;
}

.overlay__scrollable {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: auto;
  padding-bottom: 3.5rem;
}

.overlay__items {
  font-size: 1.875rem;
  /* 30px; */
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: 0.234375rem;
  /* 3.75px; */
  text-align: right;
  color: #d2694a;
  text-align: center;
  padding-left: 0;
  list-style-type: none;
}

.overlay__item {
  text-decoration: none;
  padding-bottom: 3rem;
}

.overlay__item a {
  text-decoration: none;
}

.is-visible {
  visibility: visible;
}

.nav {
  display: none;
  height: 0;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .menu__button span,
  .menu__button span:before,
  .menu__button span:after {
    visibility: hidden;
    display: none;
  }

  .overlay {
    display: none;
    visibility: hidden;
  }

  .nav {
    display: flex;
  }
}

.nav__items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.875rem;
  /* 30px; */
  font-size: 1rem;
  /* 16px; */
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.109375rem;
  /* 1.75; */
  letter-spacing: 0.125rem;
  /* 2px; */
  color: #edd8e9;
  list-style-type: none;
  padding: 0;
}

.nav__item,
.nav__item a {
  font-family: 'IBMPlexSerifItalic';
  text-decoration: none;
  margin-right: 0.625rem;
  /* 10px; */
}

.nav__item--icon {
  margin-left: 0.625rem;
  /* 10px; */
}

.nav__item--mailinglist {
  display: none;
}

@media (min-width: 992px) {
  .nav__item,
  .nav__item a {
    margin-right: 0.9375rem;
    /* 15px; */
  }

  .nav__item--icon {
    margin-left: 0.9375rem;
    /* 15px; */
  }
}

@media (min-width: 1200px) {
  .nav__item,
  .nav__item a {
    margin-right: 1.875rem;
    /* 30px; */
  }

  .nav__item--icon {
    margin-left: 1.875rem;
    /* 30px; */
  }
}

@media (min-width: 1140px) {
  .nav__item--mailinglist {
    display: inline;
  }
}

#overlayToggle:checked ~ .menu__button:hover span,
#overlayToggle:checked ~ .menu__button span {
  background: transparent;
}

#overlayToggle:checked ~ .menu__button span:before {
  transform: rotate(45deg) translate(10px, 10px);
  opacity: 1;
}

#overlayToggle:checked ~ .menu__button span:after {
  transform: rotate(-45deg) translate(10px, -10px);
}

/* row css */

.row--center {
  justify-content: center;
  text-align: center;
}

.row--banner {
  color: #d2694a;
  letter-spacing: 0.234375rem;
  /*border: black 2px solid;*/

}

.row--rust {
  background-color: #d2694a;
}

.row--peach {
  background-color: #ffeee6;
}

.row--purple {
  background-color: #edd8e9;
}

.row--music {
  padding-bottom: 1.25rem;
  /* 20px; */
}

.section {
  margin-left: 0.9375rem;
  /* 15px; */
  margin-right: 0.9375rem;
  /* 15px; */
}

.section--w100 {
  width: 100%;
}

.section__title {
  font-family: 'IBMPlexSerifItalic';
  color: #d2694a;
  font-size: 1.875rem;
  /* 30px; */
  font-weight: 500;
  line-height: 0.93;
  letter-spacing: 0.234375rem;
  /* 3.75px; */
  margin-top: 1.875rem;
  /* 30px; */
}

.section__title--light {
  color: #edd8e9;
}

@media (min-width: 768px) {
  .section__title {
    font-size: 2.625rem;
    /* 42px; */
    line-height: 0.67;
    letter-spacing: 0.328125rem;
    /* 5.25px; */
    margin-top: 5rem;
    /* 80px; */
  }

  .section__title--music {
    margin-top: 4.6875rem;
    /* 75px; */
  }
}

.berne-lineart {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

@media (min-width: 768px) {
  .berne-lineart {
    margin-left: 0;
  }
}

.album {
  display: flex;
  flex-direction: column;
}

.album__icon {
  height: 100%;
}

.album__title {
  font-size: 1.125rem;
  /* 18px; */
  font-weight: 600;
  line-height: 1.56;
  letter-spacing: 0.140625rem;
  /* 2.25px; */
  color: #d2694a;
  margin-top: 0.625rem;
  /* 10px; */
  margin-bottom: 1.875rem;
  /* 30px; */
}

@media (min-width: 768px) {
  .album__title {
    margin-top: 1.25rem;
    /* 20px; */
  }
}

.concert {
  padding-bottom: 1.25rem;
  /* 20px; */
  justify-content: space-between;
}

@media (min-width: 768px) {
  .concert {
    padding-bottom: 3.125rem;
    /* 50px; */
  }
}

.concert__text {
  font-size: 1rem;
  /* 16px; */
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.125rem;
  /* 2px; */
  color: #edd8e9;
}

.concert__text--flex {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .border {
    margin-left: 1.875rem;
    /* 30px; */
    margin-right: 1.875rem;
    /* 30px; */
  }
}

@media (min-width: 768px) {
  .border .row {
    margin-left: -0.9375rem !important;
    margin-right: -0.9375rem !important;
    /* -15px */
  }
}

.ml-rm-15 {
  margin-left: -0.9375rem;
  /* -15px; */
}

.border--bottom {
  border-bottom: 0.0625rem solid #d2694a;
  /* 1px */
  margin-left: 0rem;
  padding-bottom: 1.25rem;
  /* 20px; */
  margin-right: -0.9375rem;
  /* -15px; */
}

.border--left {
  border-left: 0.0625rem solid #d2694a;
  /* 1px */
  padding-left: 0.9375rem;
  /* 15px; */
  padding-top: 1.25rem;
  /* 20px; */
  margin-left: 0.9375rem;
  /* 15px; */
  margin-top: 0rem;
}

.border--right {
  border-right: 0.0625rem solid #d2694a;
  /* 1px */
  margin-top: -0.9375rem;
  /* -15px; */
  padding-top: 0.9375rem;
  /* 15px; */
  margin-right: -0.9375rem;
  /* -15px; */
  margin-left: 0rem;
}

.border--md-bottom {
  border: 0;
}

.border--md-left {
  border: 0;
}

.border--md-right {
  border: 0;
}

@media (min-width: 768px) {
  .border--bottom {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .border--left {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .border--right {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .border--md-bottom {
    border-bottom: 0.0625rem solid #d2694a;
    /* 1px */
  }

  .border--md-left {
    border-left: 0.0625rem solid #d2694a;
    /* 1px */
  }

  .border--md-right {
    border-right: 0.0625rem solid #d2694a;
    /* 1px */
  }
}

.mailing-form {
  padding-bottom: 3.75rem;
  /* 60px; */
}

.button {
  width: 100%;
  height: 4.25rem;
  /* 68px; */
  font-size: 1.625rem;
  /* 26px; */
  font-weight: 600;
  line-height: 1.08;
  letter-spacing: 0.203125rem;
  /* 3.25px; */
  text-align: center;
  border-radius: 1.6875rem;
  /* 27px; */
  border: 0.0625rem solid #edd8e9;
  /* 1px */

  display: inline-block;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  /* 6px 12px; */
}

.button--mailing {
  background-color: #edd8e9;
  color: #d2694a;
}

.button--banner {
  background-color: #d2694a;
  color: #edd8e9;
}

.button:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .button {
    height: 3.375rem;
    /* 54px; */
    width: 13.75rem;
    /* 220px; */
  }
}

@media (min-width: 1200px) {
  .button {
    width: 20rem;
    /* 320px; */
  }
}

.footer {
  height: 6rem;
  /* 96px; */
  align-items: center;
  justify-content: space-between;
}

.footer__text {
  color: #d2694a;
}

.footer__text--title {
  font-family: 'IBMPlexSerifItalic';
  font-size: 1.875rem;
  /* 30px; */
  font-weight: 200;
  line-height: normal;
  letter-spacing: 0.344375rem;
  /* 5.51px; */
  text-decoration: none;
}

.footer__text--imprint {
  font-family: 'Advent Pro';
  font-size: 1rem;
  /* 16px; */
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.125rem;
  /* 2px; */
  color: inherit;
}

@media (min-width: 768px) {
  .footer {
    height: 6.5rem;
    /* 104px; */
  }

  .footer__text--title {
    font-size: 3.75rem;
    /* 60px; */
    letter-spacing: 0.689375rem;
    /* 11.03px; */
  }

  .footer__text--imprint {
    text-align: center;
  }
}

.text-muted {
  color: #edd8e9 !important;
  margin-bottom: 0.625rem;
  /* 10px; */
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  scroll-behavior: smooth;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container-fluid {
  width: 100%;
  padding-right: 0.9375rem;
  /* 15px; */
  padding-left: 0.9375rem;
  /* 15px; */
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  /* -15px; */
  margin-left: -0.9375rem;
  /* -15px; */
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-sm-3,
.col-sm-7,
.col-md-2,
.col-md-3,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-10,
.col-md,
.col-lg-3,
.col-lg-5,
.col-lg-8,
.col-lg-12,
.col-xl-6,
.col-xl-8 {
  position: relative;
  width: 100%;
  padding-right: 0.9375rem;
  /* 15px; */
  padding-left: 0.9375rem;
  /* 15px; */
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

@media (min-width: 576px) {
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }
}

.justify-content-center {
  justify-content: center !important;
}

@media (min-width: 768px) {
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

@media (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pr-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
}

.form-control {
  height: 4.25rem;
  /* 68px; */
  border-radius: 3px;
  border: solid 1px #edd8e9;
  font-size: 24px;
  display: block;
  width: 100%;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 600;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 0.0625rem solid #ced4da; */
  /* 1px */
  /* border-radius: 0.25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 768px) {
  .form-control {
    height: 3.375rem;
    /* 54px; */
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated {
  color: #28a745;
}

.was-validated {
  border-color: #28a745;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #301e1c;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-feedback {
  display: block;
}
